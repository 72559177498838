






































import { Vue, Component, Watch } from 'vue-property-decorator';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import CustomAutocomplete from '@/vue-app/components/CustomAutocomplete.vue';
import ContractSavingsProfilingInvestmentProfileViewModel
  from '@/vue-app/view-models/components/contract-savings/contract-savings-profiling-investment-profile-view-model';

@Component({
  name: 'ContractSavingsProfilingInvestmentProfile',
  components: {
    CustomAutocomplete,
    InvestorProfilePersonal: () => import('@/vue-app/components/contract-savings/profiling/allianz-investor-profile/InvestorProfilePersonal.vue'),
    InvestorProfileLevelExperience: () => import('@/vue-app/components/contract-savings/profiling/allianz-investor-profile/InvestorProfileLevelExperience.vue'),
    InvestorProfileInvestmentType: () => import('@/vue-app/components/contract-savings/profiling/allianz-investor-profile/InvestorProfileInvestmentType.vue'),
  },
})
export default class ContractSavingsProfilingInvestmentProfile extends Vue {
  profiling_investment_profile_view_model = Vue.observable(
    new ContractSavingsProfilingInvestmentProfileViewModel(this),
  );

  @Watch('profiling_investment_profile_view_model.is_loading')
  onLoadChange(is_loading: boolean) {
    this.$emit('loadingInfo', is_loading);
  }

  async created() {
    await this.profiling_investment_profile_view_model.initialize(getScreenSizeVariant());
  }
}

