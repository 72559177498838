import TYPES from '@/types';
import { v4 as uuid } from 'uuid';
import Vue from 'vue';

// Application
import { SearchTemplateQuestionnairesQuery }
  from '@/modules/my-investment/catalogs/allianz/template-questionnaire/application/queries/';
import {
  SearchOnBoardingStepsQuery,
} from '@/modules/my-investment/on-boarding-steps/application/queries';
import {
  SearchInvestmentProvidersQuery,
} from '@/modules/my-investment/investment-provider/application/queries';
import {
  CreateOnBoardingStepCommand,
  UpdateOnBoardingStepCommand,
} from '@/modules/my-investment/on-boarding-steps/application/commands';

// Domain
import {
  FilterDto,
} from '@/modules/my-investment/catalogs/allianz/template-questionnaire/domain/dtos/filter-dto';
import {
  TemplateQuestionnaireEntity,
} from '@/modules/my-investment/catalogs/allianz/template-questionnaire/domain/entities/template-questionnaire-entity';
import {
  OnBoardingStepEntity,
} from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import {
  InvestmentProviderEntity,
} from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import Inject from '@/modules/shared/domain/di/inject';

export default class ContractSavingsProfilingInvestmentProfileViewModel {
  @Inject(TYPES.SEARCH_CATALOG_ALLIANZ_QUESTIONNAIRE_TEMPLATE_QUERY)
  private readonly search_template_questionnaires_query!: SearchTemplateQuestionnairesQuery;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
  private readonly search_on_boarding_steps_query!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_PROVIDER_QUERY)
  private readonly search_investment_providers_query!: SearchInvestmentProvidersQuery;

  @Inject(TYPES.CREATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly create_on_boarding_step_command!: CreateOnBoardingStepCommand;

  @Inject(TYPES.UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly update_on_boarding_step_command!: UpdateOnBoardingStepCommand;

  private readonly view!: Vue;

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.contract-savings.profiling.investment-profile';

  readonly investment_provider_name = 'allianz';

  readonly step_name = 'investor_profile';

  readonly next_step_name = 'select_investor_profile';

  readonly STEPS = [
    'InvestorProfilePersonal',
    'InvestorProfileLevelExperience',
    'InvestorProfileInvestmentType',
  ];

  readonly STEPS_TITLES = [
    'personal',
    'level_experience',
    'investment_type',
  ]

  current_step = 0;

  current_component = this.STEPS[this.current_step];

  exists_step = false;

  investment_provider_id = '';

  is_loading = true;

  load_finished = false;

  filter_template: FilterDto = {
    tipoCuestionario: 'PERFILAMIENTO_INVERSION',
    versionCuestionario: '1.0.0',
  };

  small_screen = false;

  template_questionnaires: Array<TemplateQuestionnaireEntity>=[];

  object_format = {
    llave: '',
    valor: '',
    descripcion: '',
  }

  on_boarding_step: OnBoardingStepEntity = {
    id: uuid(),
    current_step: '',
    investment_provider_id: '',
    payload: {
      etfs: this.object_format,
      actions: this.object_format,
      age_range: this.object_format,
      derivatives: this.object_format,
      deb_instruments: this.object_format,
      educational_level: this.object_format,
      investment_horizon: this.object_format,
      investment_companies: this.object_format,
      investment_currencies: this.object_format,
      investor_type_moderate: this.object_format,
      investor_type_selected: this.object_format,
      income_in_the_last_years: this.object_format,
      investor_type_aggressive: this.object_format,
      investor_type_conservative: this.object_format,
      investments_in_the_last_year: this.object_format,
      investor_calculated_points: 0,
      investor_type_selected_profile: null,
      internal_step: 'InvestorProfilePersonal',
    },
  };

  public constructor(view: Vue) {
    this.view = view;
  }

  get current_component_title() {
    return `${this.current_step + 1} / ${this.STEPS.length} ${this.translate(this.STEPS_TITLES[this.current_step])}`;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  setInvestmentProviderId = (investment_providers: Array<InvestmentProviderEntity>) => {
    const search_provider = investment_providers.find(
      (item) => item.name === this.investment_provider_name,
    );
    if (search_provider) {
      this.investment_provider_id = search_provider.id;
    }
  };

  verifyInternalStep = (
    internal_step: string | null,
    next_step: OnBoardingStepEntity | undefined,
  ) => {
    const initialized_step = (internal_step && this.STEPS.indexOf(internal_step)) || 0;
    this.current_step = initialized_step;
    this.current_component = this.STEPS[this.current_step];
  }

  verifyStep = async (on_boarding_steps: Array<OnBoardingStepEntity>) => {
    const search_step = on_boarding_steps.find(
      (step) => step.current_step === this.step_name,
    );

    const founded_next_step = on_boarding_steps.find(
      (step) => step.current_step === this.next_step_name,
    );

    if (search_step) {
      this.exists_step = true;
      this.on_boarding_step = search_step;
      await this.verifyInternalStep(
        search_step.payload.internal_step, founded_next_step,
      );
    }
  };

  setItems = (templateQuestionnaires: Array<TemplateQuestionnaireEntity>) => {
    if (templateQuestionnaires.length) {
      // eslint-disable-next-line prefer-destructuring
      this.template_questionnaires = templateQuestionnaires;
    }
  }

  calculateInvestorPoints = () => {
    let total_points = 0;
    try {
      const { payload } = this.on_boarding_step;
      const points_sophisticated = Number(payload.income_in_the_last_years.valor)
        || Number(payload.investments_in_the_last_year.valor);
      total_points = Number(payload.etfs.valor)
        + Number(payload.actions.valor)
        + Number(payload.age_range.valor)
        + Number(payload.derivatives.valor)
        + Number(payload.deb_instruments.valor)
        + Number(payload.investment_horizon.valor)
        + Number(payload.investment_companies.valor)
        + Number(payload.investment_currencies.valor)
        + Number(payload.investor_type_selected.valor)
        + points_sophisticated;
    } catch {
      this.messageNotifier.showErrorNotification(this.translate('errors.on_save_step'));
    }
    return total_points;
  }

  saveStep = async () => {
    this.is_loading = true;
    try {
      const calculated_points = this.calculateInvestorPoints();
      if (calculated_points > 0) {
        this.on_boarding_step.current_step = this.step_name;
        this.on_boarding_step.investment_provider_id = this.investment_provider_id;
        this.on_boarding_step.payload.investor_calculated_points = calculated_points;
        if (this.exists_step) {
          delete this.on_boarding_step.customer_id;
          await this.update_on_boarding_step_command.execute(this.on_boarding_step);
        } else {
          await this.create_on_boarding_step_command.execute(this.on_boarding_step);
          this.exists_step = true;
        }
      }
    } catch {
      this.messageNotifier.showErrorNotification(this.translate('errors.on_save_step'));
      this.is_loading = false;
      return false;
    }
    this.is_loading = false;
    return true;
  }

  loadTemplateQuestionnaire = async () => {
    try {
      const template_questionnaires = await this.search_template_questionnaires_query.execute(
        this.filter_template,
      );
      this.setItems(template_questionnaires);
    } catch {
      this.messageNotifier.showErrorNotification(this.translate('errors.error_load_questionnaire'));
    }
  }

  loadInvestmentProviders = async () => {
    try {
      const investment_providers = await this.search_investment_providers_query.execute();
      this.setInvestmentProviderId(investment_providers);
    } catch {
      this.messageNotifier.showErrorNotification(this.translate('errors.error_load_investment_providers'));
    }
  }

  loadSteps = async () => {
    try {
      const steps = await this.search_on_boarding_steps_query.execute(this.investment_provider_id);
      await this.verifyStep(steps);
    } catch {
      this.messageNotifier.showErrorNotification(this.translate('errors.error_load_steps'));
    }
  }

  nextStepProfile = async (updated_step: OnBoardingStepEntity) => {
    this.on_boarding_step = updated_step;
    const save_step = await this.saveStep();
    if (save_step) {
      this.current_step += 1;
      if (this.current_step < this.STEPS.length) {
        this.current_component = this.STEPS[this.current_step];
      } else {
        this.nextStep();
      }
    }
  }

  prevStepProfile = () => {
    if (this.current_step > 0) {
      this.current_step -= 1;
      this.current_component = this.STEPS[this.current_step];
    } else {
      this.prevStep();
    }
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = () => {
    this.view.$emit('nextStep');
  }

  initialize = async (screen_size: string) => {
    this.small_screen = screen_size === 'small';
    await this.loadTemplateQuestionnaire();
    await this.loadInvestmentProviders();
    await this.loadSteps();
    this.is_loading = false;
    this.load_finished = true;
  }
}
